// export const SliderData = [
//   // {
//   //   image:
//   //     "https://images.unsplash.com/photo-1583121274602-3e2820c69888?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8ZmVycmFyaXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
//   // },

//   {
//     image:
//     "../img/ferrari-cover-photo.png" 
//   },
//   {
//     image:
//       "../img/mclaren-cover-photo.png"
//   },
//   {
//     image:
//       "https://images.unsplash.com/photo-1534093607318-f025413f49cb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fHBvcnNjaGV8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
//   },
// ];

// export default SliderData;


// export const SliderData = [
//   {
//     image: require("../img/ferrari-cover-photo.png").default,
//   },
//   {
//     image: require("../img/mclaren-cover-photo.png").default,
//   },
//   {
//     image: require("../img/porsche-cover-photo.png").default,
//   },
// ];

// export default SliderData;

import image1 from "../img/ferrari-cover-photo-1.webp";
import image2 from '../img/mclaren-cover-photo-1.webp';
import image3 from "../img/porsche-cover-photo-2.webp";

export const SliderData = [
  {
    src: image1,
  },
  {
    src: image2,
  },
  {
    src: image3,
  },
];

export default SliderData;
